import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { format, formatRelative } from 'date-fns';
import { nb } from 'date-fns/locale';
import React from 'react';
import styled from 'styled-components';
import { EmbedType, LivestreamType } from '../../../../../../types/livestreamBlockType';
import { DEFAULT_IMG_URL } from '../../../../lib/constants';
import Play from '../../../icons/Play';
import { HoverWrap } from '../LatestArticleBlock/LatestArticleItem';

type LivestreamRowProps = { onSelect: () => void; video: LivestreamType };

function getThumbnailLink(url: string | null, embed: EmbedType | null) {
  switch (embed?.embedType) {
    case 'youtubeEmbed': {
      return `https://img.youtube.com/vi/${embed.youtubeEmbed.id}/default.jpg`;
    }

    default: {
      if (url) {
        // LEGACY, to be deleted once all relevant streams in Sanity have had the new format added
        const queryParamIndex = url.indexOf('?');
        const id = url.slice(url.lastIndexOf('/') + 1, queryParamIndex !== -1 ? queryParamIndex : undefined);
        return `https://img.youtube.com/vi/${id}/default.jpg`;
      }

      return DEFAULT_IMG_URL;
    }
  }
}

function handleKeypress(event: React.KeyboardEvent, callback: () => void) {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault();
    event.stopPropagation();
    callback();
  }
}

function getStartTimeLabel(startTimeString: string | null) {
  if (startTimeString === null) return '';

  const startTime = new Date(startTimeString);

  if (startTime > new Date()) {
    return `Sendingen starter ${format(startTime, "dd. MMM. 'kl'. HH:mm")}`;
  }

  return `Sending fra ${formatRelative(startTime, Date.now(), {
    weekStartsOn: 1,
    locale: nb,
  })}`;
}

const LivestreamRow = ({ onSelect, video }: LivestreamRowProps) => (
  <RowContainer onClick={onSelect} onKeyUp={(e) => handleKeypress(e, onSelect)} tabIndex={0} role="button">
    <ThumbnailContainer>
      <ThumbnailImage src={getThumbnailLink(video.url, video.embed)} alt={video.title} />
      <ThumbnailIconWrapper>
        <Play />
      </ThumbnailIconWrapper>
    </ThumbnailContainer>
    <TextWrapper>
      <RowTitle variant={TextVariant.Header3}>
        <HoverWrap>{video.title}</HoverWrap>
      </RowTitle>
      <RowStartTime variant={TextVariant.BodyText}>{getStartTimeLabel(video.startTime)}</RowStartTime>
    </TextWrapper>
  </RowContainer>
);

const RowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${spacing.space24};
  cursor: pointer;
`;

const ThumbnailContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: ${spacing.space4};

  width: 160px;
  aspect-ratio: 16 / 9;
`;

const ThumbnailImage = styled.img`
  min-width: 100%;
  min-height: 100%;
`;

const ThumbnailIconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 25px;

  & > svg {
    // The play icon is horizontally imbalanced; this margin optically centers it
    margin-left: ${spacing.space4};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  row-gap: ${spacing.space4};

  &:hover {
    ${HoverWrap} {
      background-size: 100% 2px;
    }
  }
`;

const RowTitle = styled(Text)`
  font-weight: medium;
  margin: 0;
`;

const RowStartTime = styled(Text)`
  color: ${nafColor.neutral.neutral4};
  margin: 0;
`;

export { LivestreamRow };
