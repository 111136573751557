import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';
import { EmbedType, LivestreamType } from '../../../../../../types/livestreamBlockType';
import { YoutubeEmbed } from '../../../YoutubeEmbed';

type ActiveVideoProps = { video: LivestreamType };

const ActiveVideo = ({ video }: ActiveVideoProps) => (
  <ActiveStreamWrapper>
    <VideoTitle variant={TextVariant.Header1}>{video.title}</VideoTitle>
    <VideoEmbed embed={video.embed} url={video.url} />
  </ActiveStreamWrapper>
);

type VideoEmbedProps = { embed: EmbedType | null; url: string | null };

const VideoEmbed = ({ embed, url }: VideoEmbedProps) => {
  switch (embed?.embedType) {
    case 'youtubeEmbed':
      return <YoutubeEmbed embed={embed.youtubeEmbed} />;
    default:
      if (url !== null) {
        // Fallback to legacy
        return (
          <EmbedIframe
            src={url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        );
      }
      return null;
  }
};

const ActiveStreamWrapper = styled.div`
  width: 100%;
`;

const VideoTitle = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space16};
`;

const EmbedIframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  display: block;
  border: 0;
`;

export { ActiveVideo };
