import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
import { LivestreamBlockType, LivestreamType } from '../../../../../types/livestreamBlockType';
import { ActiveVideo } from './LivestreamBlock/ActiveVideo';
import { LivestreamRow } from './LivestreamBlock/LivestreamRow';

type LivestreamBlockProps = {
  block: LivestreamBlockType;
};

function getKeyByType(livestream: LivestreamType) {
  switch (livestream.embed?.embedType) {
    case 'youtubeEmbed':
      return livestream.embed?.youtubeEmbed.id;
    default:
      return livestream.url || livestream.title;
  }
}

const LivestreamBlock = ({ block }: LivestreamBlockProps) => {
  const [activeStreamIndex, setActiveStreamIndex] = useState(0);
  return (
    <LivestreamBlockWrapper>
      <ActiveVideo video={block.livestreams[activeStreamIndex]} />

      {block.livestreams.length > 1 ? (
        <>
          <HLine />
          <LivestreamList>
            {block.livestreams
              .filter((_, i) => i !== activeStreamIndex)
              .map((livestream, i) => {
                const setStreamActive = () => setActiveStreamIndex(i >= activeStreamIndex ? i + 1 : i);
                return <LivestreamRow key={getKeyByType(livestream)} video={livestream} onSelect={setStreamActive} />;
              })}
          </LivestreamList>
          <HLine />
        </>
      ) : null}
    </LivestreamBlockWrapper>
  );
};

const HLine = styled.hr`
  border: none;
  height: 1px;
  background-color: ${nafColor.neutral.neutral2};
  margin: ${spacing.space16} 0;
`;

const LivestreamList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${spacing.space16};
`;

// Allows this block to be selected by nesting styled-components
const LivestreamBlockWrapper = styled.div``;

export { LivestreamBlock, LivestreamBlockWrapper };
